import React from 'react';

import "./src/styles/global.css";
import "./src/styles/custom.scss";
import "./src/styles/typography.css";
import 'lazysizes';

export const wrapRootElement = ({ element }) => {
  return (
    <div className="antialiased subpixel-antialiased">
      {element}
    </div>
  );
};