exports.components = {
  "component---src-blog-blog-detail-jsx": () => import("./../../../src/blog/blog-detail.jsx" /* webpackChunkName: "component---src-blog-blog-detail-jsx" */),
  "component---src-blog-blog-jsx": () => import("./../../../src/blog/blog.jsx" /* webpackChunkName: "component---src-blog-blog-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-index-jsx": () => import("./../../../src/pages/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-index-jsx" */),
  "component---src-pages-projects-chatyuk-jsx": () => import("./../../../src/pages/projects/chatyuk.jsx" /* webpackChunkName: "component---src-pages-projects-chatyuk-jsx" */),
  "component---src-pages-projects-cryptogalaxy-jsx": () => import("./../../../src/pages/projects/cryptogalaxy.jsx" /* webpackChunkName: "component---src-pages-projects-cryptogalaxy-jsx" */),
  "component---src-pages-projects-dickaismaji-jsx": () => import("./../../../src/pages/projects/dickaismaji.jsx" /* webpackChunkName: "component---src-pages-projects-dickaismaji-jsx" */),
  "component---src-pages-projects-gojek-react-native-jsx": () => import("./../../../src/pages/projects/gojek-react-native.jsx" /* webpackChunkName: "component---src-pages-projects-gojek-react-native-jsx" */),
  "component---src-pages-projects-pantau-covid-jsx": () => import("./../../../src/pages/projects/pantau-covid.jsx" /* webpackChunkName: "component---src-pages-projects-pantau-covid-jsx" */),
  "component---src-pages-projects-scsscompiler-jsx": () => import("./../../../src/pages/projects/scsscompiler.jsx" /* webpackChunkName: "component---src-pages-projects-scsscompiler-jsx" */),
  "component---src-pages-projects-tokopedia-nextjs-jsx": () => import("./../../../src/pages/projects/tokopedia-nextjs.jsx" /* webpackChunkName: "component---src-pages-projects-tokopedia-nextjs-jsx" */)
}

